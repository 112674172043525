<template>
    <div title="Ultimo Nível do Silo recebido Hoje">
      <a class="card-button" @click="changeBarnTarget(barn, batch, sensors, '/batch-details/smart-feed')">
        <AnimadePlaceHolder v-if="showFeed" height="40px" width="100%"  borderRadius="4px"/>
        <div class="feed-data-wrapper" v-else>      
          <div
            class="progress-bar progress feed-data"
            style="width: 100%; padding: 0; margin: 0 2px; border-radius: 4px"
            role="progressbar"
            :class="smartFeedColor(feed)"
            :key="feed.sensor"
            v-for="feed in dataFeed"
          >
            <div  class="container-information-td " >
              <img  class="imgIcon" src="../assets/silo-white.svg" />
              <p class="product-information fonteSenlote " >
                  {{feed.message != undefined ? 'SEM DADOS' : `${feed.last_hour_level} %`}}
              </p>
            </div>
          </div>
          <AnimadePlaceHolder v-if="sensors.length != dataFeed.length" height="40px" width="100%"  borderRadius="4px"/>
        </div> 
      </a>
    </div>
</template>

<script>
import { URL } from "../store/index"
import AnimadePlaceHolder from './AnimadePlaceHolder.vue'

export default {
  name: "SensorsFeed",
  components: {
    AnimadePlaceHolder,
  },
  data() {
    return {
      dataFeedFromFetch:[],
      loading: true
    }
  },
  props: ["sensors", "indexBarn", 'barn', 'batch'],
  methods: {
    smartFeedColor(feed) {
      if ( this.batch?.message) return 'feed-disabled'
      if (feed.message !== undefined) return 'feed-disabled'
      if (this.batch[0]?.date_departure != null) return 'feed-disabled'
      if (feed.last_hour_level > 30) {
        return 'peso-feed-bom'
      }
      if (feed.last_hour_level > 15) {
        return 'peso-feed-medio'
      }
      return 'peso-feed-ruim'
    },
    async getDataFeed() {
      for (const element of this.sensors) {
        try {
          this.$store.commit('incrementActiveRequests');
          let url_to_fetch = `${URL}sensors/report/data/home/smartfeed/smartfeed_pk=${element.pk}`;
          const response = await fetch(url_to_fetch, {
              headers: this.$store.getters.getHeader
          });
          const json = await response.json();
          if (json[0] == undefined) {
            this.dataFeedFromFetch.push(json);
          } else {
            this.dataFeedFromFetch.push(json[0]);
          }

          this.loading = false
          const allDataFeed = {
            barn: this.barn,
            batch: this.batch[0],
            sensor: this.sensors,
            data:this.dataFeedFromFetch
          }
          this.$store.commit('setSensorsListFeed', allDataFeed)
        } finally {
          this.$store.commit('decrementActiveRequests');
        }
      }
    },
    changeBarnTarget(barn, batch, sensors, route) {
      if (this.dataFeed.length > 0) {
        this.$store.commit("setBarnTarget", barn)
        this.$store.commit("setBatchTarget", batch[0] == undefined ? null : batch[0])
        this.$router.push(route)
      }
    },
  },
  computed: {
    getDataFeedSave(){
      return this.$store.getters.getSensorsListFeed
    },
    selectedFeed() {
      return this.$store.getters.getSensorsListFeed.find(obj => obj.batch.pk == this.batch[0].pk)
    },
    dataFeed() {
      if (this.isFirstBatch == 'Sem lote cadastrado neste barracão.') {
        return this.dataFeedFromFetch
      }
      return this.selectedFeed?.data ?? []
    },
    isFirstBatch() {
      return this.batch?.message ?? ''
    },
    showFeed() {
      if (this.isFirstBatch == 'Sem lote cadastrado neste barracão.') {
        return this.dataFeedFromFetch.length == 0
      }
      return this.getDataFeedSave.length == 0
    }
  },
  async created() {
    if (this.dataFeed.length == 0) {
      await this.getDataFeed()
    }
  }
};

</script>

<style scoped>
.cursor{
  cursor: not-allowed;
}

.progress {
  border-radius: 0;
  height: 40px;
  background-color: #f4f4f4;
  font-weight: bold;
  font-size: 14px;
}

.progress-bar {
  text-align: left;
  padding-left: 10px;
}

.progress-bar span {
  color: #3e3e3e;
  background: url(../assets/listras.png) #fcfdfd;
  width: fit-content;
  padding: 0 10px;
  border-radius: 10px;
}

a {text-decoration: none;}
.peso-atual-acima .progress-bar {background: #415430;}
a .peso-atual-acima .progress-bar:hover {background: #273619;}
.peso-atual-bom .progress-bar {background: #a1cd7b;}
a .peso-atual-bom .progress-bar:hover {background: #5e7d42;}
.peso-atual-medio .progress-bar {background: #e2bf65;}
a .peso-atual-medio .progress-bar:hover {background: #c59e3a;}
.peso-atual-ruim .progress-bar {background: #a94949;}
a .peso-atual-ruim .progress-bar:hover {background: #952323;}
.no-sensor .progress-bar {background:  #f2f2f2; color: grey;}
a .no-sensor .progress-bar:hover {background: #cfcfcf;}
.disable .progress-bar {background:  #adadad;}
a .disable .progress-bar:hover {background: grey;}

.feed-data-wrapper {
  display: flex;
  width: 100%;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.feed-data {
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1); 

}

.feed-data:hover {
  animation: scaleAnimation 0.3s ease-in-out 25ms forwards; 
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);

  }
}

.container-information-td{
  display: flex;
  align-items: center;
  justify-content: center;

}

.imgIcon{
  width: 20px;
  margin: 4px;
  margin-right: 8px;

}

.feed-data {
  width: -moz-available;
  width: -webkit-fill-available;
}

.peso-feed-acima {background: #415430;}
.peso-feed-acima:hover {background: #273619;}
.peso-feed-bom {background: #a1cd7b;}
.peso-feed-bom:hover {background: #5e7d42;}
.peso-feed-medio {background: #e2bf65;}
.peso-feed-medio:hover {background: #c59e3a;}
.peso-feed-ruim {background: #a94949;}
.peso-feed-ruim:hover {background: #952323;}
.feed-disabled {background:  #adadad;}
.feed-disabled:hover {background: grey;}

.product-information {
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  font-size: 14px
}

.card-button {
  cursor: pointer;
}

@media screen and (min-width: 991px) {
  .imgIcon{
    display: none;
  }
}
</style>
