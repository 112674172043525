<script>
import { Scatter } from 'vue-chartjs'
import { parseISO} from 'date-fns';

export default {
  name: "SmartCamChart",
  extends: Scatter,
  props: ["min_x", "max_x", "sets", "label", "min_y", "max_y", "camDates", "camManualDates"],
  data() {
    const min_x = this.min_x 
    const max_x = this.max_x 
    const dates = this.camDates
    const manualDates = this.camManualDates
    
    return {
      weightData: {
        datasets: this.sets
      },
      options: {
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              const label = data.datasets[tooltipItem[0].datasetIndex].label
              if (label === "Esperado" || label === "GPD Esperado" || label === "Manual") return `${label}`
              return 'SmartCam'
            },
            beforeLabel: function(tooltipItem) {
              return `Andamento: dia ${tooltipItem.xLabel}`;
            },
            label: function(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label
              if (label === "Losses") return `Animais [Unid]: ${tooltipItem.yLabel}`
              if (label === "Uniformidade") return `${label}: ${tooltipItem.yLabel} %`
              if (label === "SmartCam" || label === "Manual") return `Peso: ${tooltipItem.yLabel.toFixed(2)} kg`
              return `${label}: ${tooltipItem.yLabel.toFixed(2)} kg`
            },
            afterLabel: function(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label
              let tooltip_date;
              if (label === "Manual") {
                tooltip_date = new Date(manualDates[tooltipItem.index]);
              }
              else {
                tooltip_date = parseISO(dates[tooltipItem.index]);
              }
              tooltip_date.setDate(tooltip_date.getDate())
              const day = String(tooltip_date.getDate()).padStart(2, "0");
              const month = String(tooltip_date.getMonth() + 1).padStart(2, "0");
              const year = tooltip_date.getFullYear();
              tooltip_date = day + "/" + month + "/" + year;
              if (label === "Esperado" || label === "GPD Esperado") return ''
              
              return `Data: ${tooltip_date}`
            },
          },
          backgroundColor: '#fff',
          titleFontSize: 16,
          titleFontColor: '#89c25b',
          bodyFontColor: '#000',
          bodyFontSize: 14,
          displayColors: false,
          borderColor: '#89c25b',
          borderWidth: 2,
          custom: function(tooltipModel) {
            let title;
            if (tooltipModel.title != null) {
              title = tooltipModel.title[0]
            }
            else {
              title = ''
            }

            if (title === "Manual") {
              tooltipModel.borderColor = '#87165c'
              tooltipModel.titleFontColor = '#87165c'
            }
            else {
              if (title === "Esperado" || title === "GPD Esperado") {
                tooltipModel.borderColor = '#171a5c'
              tooltipModel.titleFontColor = '#171a5c'
              }
              else {
                tooltipModel.titleFontColor = '#89c25b';
              }
            }
          }
        },
        title: {
          display: true,
          text: "Curvas de crescimento",
          fontSize: 20
        },
        legend: {
          display: true,
          position: "top"
        },
        scales: {
          xAxes: [{
            ticks: {
              min: min_x,
              max: max_x,
            },
            scaleLabel: {
              display: true,
              labelString: 'Andamento [dias]',
              fontSize: 16
            },
            gridLines: {
              color: "rgba(120, 184, 67, .125)",
              display: true,
            }
          }],
          yAxes: [{
            ticks: {
              min: this.min_y,
              max: this.max_y
            },
            scaleLabel: {
              display: true,
              labelString: this.label,
              fontSize: 16
            },
            gridLines: {
              color: "rgba(120, 184, 67, .125)",
              display: true,
            }
          }]
        },
        layout:{
          padding: {
            left: 0
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        stroke: {
          curve: 'straight'
        },
      }
    }
  },
  mounted () {
    this.renderChart(this.weightData, this.options)
  }
}
</script>
