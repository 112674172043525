<template>
  <div class="container-smartfeed" id="">
    <h3 style="text-align: center" v-if="showProfileVisualization">Nome: {{sensor.name}} </h3>
    <div class="info-container" >
      <div :class="styles" :id="stylesPecsmart">
        <div class="silo">
          <template v-if="showProfileVisualization" >
            <SmartFeedProfileHistory :sensor="sensor" :sacaWeight="sacaWeight" :key="sensor.pk" :startDate="startDate" :endDate="endDate" :seekNewRange="seekNewRange"/>
          </template>
          <template v-else>
            <div class="container" v-if="sensorsData.message == undefined">
              <Silo v-bind:level="sensorsData.last_hour_level" :useInformedWeight="useInformedWeight" v-bind:weight="sensorsData.last_hour_weight" :sensor="sensor"/>
            </div>
          </template>
        </div>
        <PageLoader v-if="userLevel === 'pecsmart' && smartFeedDataChart == undefined"/>
        <div class="clear"></div>
         <PageLoader v-if="loading"/>
        <div v-if="userLevel === 'pecsmart' && smartFeedDataChart !== undefined && loading == false" class="">
          <div class="container-charts" style="margin-top: 0; padding: 0">
            <div style="position: relative;" class="conteudo" >
              <div v-if="chartWarning(smartFeedDataChart.level_history)" class="chart-without-data">
                <h1>SEM DADOS</h1>
                <h1>SUFICIENTES</h1>
              </div>

              <SmartFeedLevelChart
                :key="componentKeyLevel"
                v-on:mouseover.native="forceRerenderLevel()"
                v-bind:sets="levelChatData"
                v-bind:yAxesScale="true"
                v-bind:first_date="accommodation"
                v-bind:last_date="last_date"
                v-bind:text="'HISTÓRICO DE NÍVEL'"
                :height="userLevel !== 'employee' ? 250 : 300"
              />
            </div>
          </div>
          <div style="position: relative;" class="conteudo">
            <div v-if="chartWarning(siloLoadDataset) && chartWarning(siloLoadDatasetAccumulated)" class="chart-without-data">
              <h1>SEM DADOS</h1>
              <h1>SUFICIENTES</h1>
            </div>
            <div class="chart-active">
              <div class="chart-title">
                <p>Diário</p>
              </div>
              <SliderButton v-bind:TrackType="'track-load'" @changeChart="changeLoadChart()" />
              <div class="chart-title">
                <p>Acumulado</p>
              </div>
            </div>
            <SmartFeedChart
              :key="componentKeyLoad"
              v-bind:sets="loadChartDisplay"
              v-bind:yAxesScale="yAxesScaleLoad"
              v-bind:offset="chartOffsetLoad"
              v-bind:first_date="accommodation"
              v-bind:last_date="last_date"
              v-bind:text="'ABASTECIMENTO'"
              :height="200"
              :width="800"
            />
          </div> 
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import Silo from "./Silo.vue"
import SmartFeedChart from "./SmartFeedChart.vue"
import SmartFeedLevelChart from "./SmartFeedLevelChart.vue"
import SliderButton from "./SliderButton.vue"
import SmartFeedProfileHistory from "./SmartFeedProfileHistory.vue";
import PageLoader from "../components/Loader.vue"
import { URL } from "../store/index"
import { format, parseISO} from 'date-fns';


export default {
  name: "SmartFeed",
  props: ["sensor", "showProfileVisualization", "useInformedWeight", "startDate", "endDate", "seekNewRange"],
  components: {
    SmartFeedChart,
    SliderButton,
    SmartFeedLevelChart,
    Silo,
    SmartFeedProfileHistory,
    PageLoader
  },
  data() {
    return {
      responseJson: null,
      loading: true,
      loadChartData: this.loadData,
      unloadChartData: this.unloadData,
      chartOffsetLoad: true,
      yAxesScaleLoad: true,
      chartOffsetUnload: true,
      yAxesScaleUnload: true,
      componentKeyLoad: 0,
      componentKeyUnload: 0,
      componentKeyLevel: 0,
      levelChartWasUpdated: false,
      newEndDate : false,
      dataChart: undefined
    }
  },
  watch: {
    async seekNewRange() {
      if (this.userLevel === 'pecsmart') {
        this.loading = true
        this.fetchDataFeedChart(this.newFormatDate(this.startDate), this.newFormatDate(this.endDate))
        this.forceRerenderLevel()
        this.forceRerenderLoad()
      }
    },
    smartFeedDataChart() {
      this.forceRerenderLevel()
      this.forceRerenderLoad()
      this.loading = false
    },
    endDate() {
      this.newEndDate = true
    }

  },
  methods: {
    newFormatDate(date){
      return format(parseISO(date), 'yyyy-MM-dd')
    },
    async fetchDataFeedChart(firstDate, lastDate){
      const barn = this.barn.pk
      const silo = this.sensor.silo
      try {
        fetch(`${URL}silo_feed_weights/report/${barn}/${silo}/${firstDate}/${lastDate}`, {
          headers: this.$store.getters.getHeader
        })
        .then(response => response.json())
        .then(json => {
          const isThereData = json?.detail ?? false
          if (isThereData) {
            const newJson = {
              silo: silo,
              barn: barn,
              data: [],
              level_history: [],
              message: json
            }
            this.dataChart = newJson
          } else{
            this.dataChart = json
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    chartWarning(dataset) {
      if (dataset.length < 1) return true
      return false
    },
    changeLoadChart() {
      this.chartOffsetLoad = !this.chartOffsetLoad
      this.yAxesScaleLoad = !this.yAxesScaleLoad
      if (this.loadChartData === this.loadData || this.loadChartData == null) {
        this.loadChartData = this.loadAccData
      }
      else {
        this.loadChartData = this.loadData
      }
      this.forceRerenderLoad()
    },
    changeUnloadChart() {
      this.chartOffsetUnload = !this.chartOffsetUnload
      this.yAxesScaleUnload = !this.yAxesScaleUnload
      if (this.unloadChartData === this.unloadData || this.unloadChartData == null) {
        this.unloadChartData = this.unloadAccData
      }
      else {
        this.unloadChartData = this.unloadData
      }
      this.forceRerenderUnload()
    },
    forceRerenderLoad: function() {
      this.componentKeyLoad += 1
    },
    forceRerenderUnload: function() {
      this.componentKeyUnload += 1
    },
    forceRerenderLevel: function() {
      if (this.levelChartWasUpdated) return;
      this.componentKeyLevel += 1
      this.levelChartWasUpdated = true
    },
  },
  computed: {
    sacaWeight() {
      switch (this.$store.getters.getCurrentUser.company) {
        case 64:
          return 25
        default:
          return 60
      }
    },
    loadChartDisplay() {
      if (this.loadChartData == null) return this.loadData
      return this.loadChartData
    },
    unloadChartDisplay() {
      if (this.unloadChartData == null) return this.unloadData
      return this.unloadChartData
    },
    computed_weight() {
      if (this.sensorsData?.last_hour_weight == null) return "Sem Peso"
      return `${this.sensorsData.last_hour_weight} kg`
    },
    selectedFeed() {
      return this.$store.getters.getSensorsListFeed.find(obj => obj.batch.pk == this.batch.pk)
    },
    smartFeedDataChart() {
      return this.dataChart
    },
    sensorsData() {
      try {
        return this.sensor
      }
      catch(err) {
        return null
      }
    },
    siloLoadDataset() {
      return this.smartFeedDataChart.data.map(item => {
        return {
          "x": item.date,
          "y": Number(item.refill)
        }
      })
    },
    siloLoadDatasetAccumulated() {
      let accumulated = 0
      return this.smartFeedDataChart.data.map(item => {
        let y = item.refill + accumulated
        accumulated += item.refill
        return {
          "x": item.date,
          "y": Number(y)
        }
      })
    },
    siloUnloadDataset() {
      return this.smartFeedDataChart.data.map(item => {
        return {
          "x": item.date,
          "y": Number(item.consume)
        }
      })
    },
    siloUnloadDatasetAccumulated() {
      let accumulated = 0
      return this.smartFeedDataChart.data.map(item => {
        let y = item.consume + accumulated
        accumulated += item.consume
        return {
          "x": item.date,
          "y": Number(y)
        }
      })
    },
    unloadData() {
      const setting= [
        {
          label: "Consumo Diário",
          pointRadius: 3,
          data: this.siloUnloadDataset,
          type: 'bar',
          fill: false,
          borderColor: "rgba(137, 194, 91, 1)",
          backgroundColor: "rgba(137, 194, 91, 1)",
          borderWidth: 2,
          yAxisID: 'Consume',
          barPercentage: 0.75,
        },
      ]
      return setting
    },
    unloadAccData() {
      const setting= [
        {
          label: "Consumo Acumulado",
          pointRadius: 3,
          data: this.siloUnloadDatasetAccumulated,
          type: 'line',
          fill: true,
          borderColor: "rgba(91, 138, 52, 0.9)",
          backgroundColor: "rgba(91, 138, 52, 0.9)",
          borderWidth: 2,
          yAxisID: 'AccumulatedConsume',
        },
      ]
      return setting
    },
    loadData() {
      const setting = [
        {
          label: 'Abastecimento Diário',
          pointRadius: 3,
          data: this.siloLoadDataset,
          type: 'bar',
          fill: false,
          borderColor: "#999999",
          backgroundColor: "#999999",
          borderWidth: 2,
          yAxisID: 'Consume',
          barPercentage: 0.75,
        }
      ]
      return setting
    },
    loadAccData() {
      const setting= [
        {
          label: "Abastecimento Acumulado",
          pointRadius: 3,
          data: this.siloLoadDatasetAccumulated,
          type: 'line',
          fill: true,
          borderColor: "rgba(110, 110, 110)",
          backgroundColor: "rgba(110, 110, 110)",
          borderWidth: 2,
          yAxisID: 'AccumulatedConsume',
        },
      ]
      return setting
    },
    levelChatData() {
      const setting = [
        {
          label: "Nível",
          pointRadius: 0,
          data: this.smartFeedDataChart?.level_history ?? [],
          type: 'line',
          fill: false,
          borderColor: "rgba(137, 194, 91, 1)",
          backgroundColor: "rgba(137, 194, 91, 1)",
          borderWidth: 3,
          steppedLine: true,
        },
      ]
      return setting
    },
    styles() {
      return this.userLevel == 'pecsmart' ? 'silo-graphic-pecsmart' : 'silo-graphic-client'
    },
    stylesPecsmart() {
      return this.showProfileVisualization && this.userLevel == 'pecsmart' ? 'silo-graphic-pecsmart-siloProfile' : ''
    },
    barn() {
      return this.$store.getters.getBarnTarget
    },
    batch() {
      if (this.$store.getters.getBatchTarget == null) {
        return this.barn.batch
      }
      return this.$store.getters.getBatchTarget
    },
    last_date() {
      let today = new Date()
      today.setDate(today.getDate())
      if (this.newEndDate) return this.endDate 
      if (this.batch == undefined) return today
      if (this.batch.date_departure != null) return this.batch.date_departure
      return today
    },
    userLevel() {
      return this.$store.getters.getUserLevel
    },
    accommodation() {
      return this.startDate
    },
  },
  async created() {
    if (this.userLevel === 'pecsmart') {
      await this.fetchDataFeedChart(this.newFormatDate(this.startDate), this.newFormatDate(this.endDate))
      this.loading = false
    }
    this.loading = false
  },
};
</script>

<style scoped>
.chart-without-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.info-container {
  width: 100%;
}

.silo-graphic-client{
  display: flex;
  justify-content: center;
}

.container-smartfeed{
  min-width: 100%;
  margin-top: 24px;
}

.chart-active {
  margin-left: 3%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  top: 0;
  left: 0;
  height: 24px;
}

.chart-active .chart-title {
  display: flex;
  align-items: center;
}

.chart-active .chart-title p {
  margin: 0;
}

.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin: 12px;
}

.warning .text {
  background: #78b843;
  border-radius: 10px;
  color: #fff;
  padding: 20px;
  font-size: 30px;
}

.warning .text h1 {
  display: flex;
  justify-content: center;
}

.warning .text p {
  display: flex;
  justify-content: center;
  margin: 0;
}

.mobile-info {
  display: none;
}

.situacao-atual-feed-bom {background: #78B843 !important;}
.situacao-atual-feed-medio {background: #e2bf65 !important;}
.situacao-atual-feed-ruim {background: #a94949 !important;}

strong {
  font-weight: 700;
}

@media screen and (max-width: 740px) {
  .container-smartfeed{
    min-width: 400px;
    max-width: 450px;
  }
}

@media screen and (max-width: 450px) {
  .container-smartfeed{
    min-width: 300px;
    max-width: 350px;
  }
}

@media screen and (max-width: 991px) {
  .mobile-info {
    display: grid;
  }
}

@media screen and (max-width: 768px) {
  .warning {
    height: 250px;
  }

  .warning h1 {
    font-size: 30px;
  }

  .warning p {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .warning h1 {
    font-size: 25px;
  }

  .warning p {
    font-size: 15px;
  }
}

@media screen and (max-width: 360px) {
  .warning {
    height: 200px;
  }

  .warning h1 {
    font-size: 20px;
  }

  .warning p {
    font-size: 10px;
  }
}

#silo-graphic-pecsmart-siloProfile{
    display: flex;
    flex-direction: column;
  }

@media screen and (min-width: 1240px) {
  .silo-graphic-pecsmart{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 1240px) {
  .container{
    justify-content: center;
  }
}

</style>
