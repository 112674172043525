<template>
  <div>
    <div class="login">
      <div class="login-header">
        <img src="../assets/logo.svg" />
      </div>

      <p class="login-icone">
        <img src="../assets/login.png" />
      </p>

      <form @submit.prevent="Login">
        <input type="text" name="username" placeholder="Nome de usuário" v-model="username" required/>
        <input type="password" name="password" placeholder="Senha" v-model="password" required/>

        <button :class="button" type="submit">Entrar</button>
      </form>
    </div>

    <div :class="loginAlert" :style="style">
      <span class="msg">Erro: Login incorreto</span>
      <div class="close-btn" v-on:click="closeAlert">
        <span class="fas ">x</span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "LoginPage",
  beforeRouteEnter(to, from, next){
    to.meta.title = "PecSmart - Login";
    document.title = to.meta.title;
    next();
  },
  data() {
    return {
      username: '',
      password: '',
      loginAlert: 'alert hide',
      style: '',
      button: 'submit-button',
    }
  },
  methods: {
    alert: function() {
      this.loginAlert = 'alert show showAlert'
      this.style = 'right: 20px;'
    },
    closeAlert: function() {
      this.loginAlert = 'alert hide'
      this.style = ''
    },

    async Login() {
      document.body.style.cursor = 'progress';
      this.button = "submit-button-disable"
      this.closeAlert()
      const user = {
        username: this.username,
        password: this.password
      }
      const isLoginPage = true
      await this.$store.dispatch("login", {user, isLoginPage})
      if (this.$store.getters.getError) {
        this.alert()
      }
      this.button = "submit-button"
      document.body.style.cursor = 'default';
    }
  },
  created() {
    this.$store.dispatch("cleanStore")
  }
};

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
*{
  margin: 0;
  padding: 0;
  user-select: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.alert{
  background: #ee5252;
  padding: 20px 40px 20px 0;
  min-width: 310px;
  position: absolute;
  bottom: 50px;
  border-radius: 4px;
  border-left: 8px solid #e22e2e;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.alert.showAlert{
  opacity: 1;
  pointer-events: auto;
}
.alert.show{
  animation: show_slide 1s ease forwards;
}
@keyframes show_slide {
  0%{
    transform: translateX(100%);
  }
  40%{
    transform: translateX(-10%);
  }
  80%{
    transform: translateX(0%);
  }
  100%{
    transform: translateX(-10px);
  }
}
.alert.hide{
  animation: hide_slide 1s ease forwards;
}
@keyframes hide_slide {
  0%{
    transform: translateX(-10px);
  }
  40%{
    transform: translateX(0%);
  }
  80%{
    transform: translateX(-10%);
  }
  100%{
    transform: translateX(100%);
  }
}
.alert .msg{
  padding: 0 20px;
  font-size: 18px;
  color: #ffffff;
}
.alert .close-btn{
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: #e63232;
  padding: 20px 18px;
  cursor: pointer;
}
.alert .close-btn:hover{
  background: #d41c1c;
}
.alert .close-btn .fas{
  color:#8a1515;
  font-size: 22px;
  line-height: 40px;
}

.login {
  background: #ffffff;
  border-radius: 8px;
  margin: 100px auto 0 auto;
}

.login-header {
  background: #78b843;
  background-image: url("../assets/login-fundo.png");
  background-size: cover;
  border-radius: 8px 8px 0 0;
  padding: 40px 0;
}

.login-header img {
  display: block;
  margin: 0 auto;
  padding: 20px;
  width: 290px;
}

.login-icone {
  padding: 0 20px;
  margin-top: -50px;
}

.login form {
  padding: 20px 0 30px 20px;
}

.login input[type="text"],
.login input[type="password"] {
  background: #f2f4f6;
  border: solid 1px #eaeaea;
  padding: 15px;
  margin-bottom: 10px;
  width: 95%;
  font-size: 16px;
  color: #656464;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: solid 1px #eaeaea;
  font-size: 16px !important;
  color: #656464;
  -webkit-text-fill-color: #656464;
  -webkit-box-shadow: 0 0 0px 1000px #f2f4f6 inset;
  box-shadow: 0 0 0px 1000px #f2f4f6 inset;
}

.submit-button {
  background: #78b843;
  color: #fff;
  border: none;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 20px 12px 20px;
  border-radius: 2px;
  margin-top: 20px;
  cursor: pointer;
}

.submit-button:hover {
  background: #1b4238;
}

.submit-button-disable {
  background: #b8b8b8;
  color: rgb(255, 255, 255);
  border: none;
  font-size: 18px;
  text-transform: uppercase;
  padding: 15px 20px 12px 20px;
  border-radius: 2px;
  margin-top: 20px;
  cursor: not-allowed;
  outline: none;
}

.login-esqueci-senha {
  padding: 0 20px 25px 20px;
}

.login-esqueci-senha p {
  float: left;
}

.login-esqueci-senha p:nth-child(1) {
  padding-right: 10px;
}

.login-esqueci-senha a {
  color: #6b6c6c;
  font-weight: 300;
  text-decoration: none;
}

.login-esqueci-senha a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1199px) {
  .login {
    width: 45%;
  }
}

@media screen and (min-width: 1201px) {
  .login {
    width: 40%;
  }
}

@media screen and (max-width: 991px) {
  .login {
    width: 60%;
  }
}

@media screen and (max-width: 767px) {
  .login {
    width: 80%;
  }
}

@media screen and (max-width: 576px) {
  .login {
    width: 95%;
  }
}

</style>
